import { ProviderService } from 'shared/models/providerService.model';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import BasesService from 'services/basesManager.service';
import { ReloadHelper } from 'shared/utils/reloadHelper.util';

export class Categories extends ProviderService {
    protected static readonly serviceName = 'categoriesService';
    private static _instance: Categories;
    private readonly _isCategoriesAvailable = ref(false);
    private readonly _isCategoriesReceived = ref(false);
    private readonly _basesManager = inject<BasesService>(BasesService.getServiceName());

    constructor() {
        if (Categories._instance) {
            return Categories._instance;
        }
        super();
        Categories._instance = this;
    }

    public set isCategoriesAvailable(isCategoriesAvailable: boolean) {
        this._isCategoriesAvailable.value = isCategoriesAvailable;
        this._isCategoriesReceived.value = true;
    }

    public get isCategoriesAvailable(): boolean {
        return this._isCategoriesAvailable.value;
    }

    public get isCategoriesReceived(): boolean {
        return this._isCategoriesReceived.value;
    }

    public static killInstance(): void {
        Categories._instance = undefined;
    }

    public async goToCategoriesPage(): Promise<void> {
        if (ReloadHelper.isReloading) {
            return;
        }
        if (!this._isCategoriesReceived.value) {
            const { category } = await PimCatalogService.getCategories(3, this._basesManager?.getCurrentRegionFiasId());
            this.isCategoriesAvailable = !!category.subcategory?.length;
        }
        await navigateTo(this.isCategoriesAvailable ? '/categories' : '/');
    }
}
