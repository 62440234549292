import { ref, unref } from 'vue';

export class ReloadHelper {
    private static _isReloading = ref(false);

    public static reload(): void {
        this._isReloading.value = true;
        location.reload();
    }

    public static get isReloading(): boolean {
        return unref(this._isReloading);
    }
}